import React, { useState, useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Typography, Box, Grid } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import cardano from './cardano.png';

const Homepage = () => {
	const theme = useTheme(); // on peut acceder au theme par un hook
	const { lang } = useParams();
	return (
		<Grid direction="row" container spacing={1} justify="center">
			<Grid item xs={12} sm={6}>
				<Box my={6}>
					<img src={cardano} alt="cardano logo" width="400px" />
				</Box>
			</Grid>
			<Grid item xs={12} sm={6}>
				<Box my={4}>
					<Typography
						variant="h1"
						style={{ textUnderlineOffset: '5px', fontSize: '3em', color: theme.palette.secondary.main }}
					>
						<span style={{ color: '#FFF', fontWeight: 'bold' }}>ADAFR</span> / ADA Staking Pool
					</Typography>
					{lang === 'fr' && (
						<Box mt={4} textAlign="center">
							<Typography variant="body1" color="secondary">
								Vous pouvez obtenir assistance en me contactant directement par mail <br />
								<br />
								<a style={{ color: '#FFF', textDecoration: 'none' }} href="mailto:regisg@gmail.com">
									regisg@gmail.com
								</a>
								<br />
								<br />
								Vous pouvez également rejoindre le{' '}<br /><br />
								<a
									style={{ color: '#FFF', textDecoration: 'none' }}
									href="https://t.me/cardanofrancemain"
								>
									Canal Télégram Français
								</a>
								<br />
								<br />
							</Typography>
						</Box>
					)}
					{lang === 'en' && (
						<Box mt={4} textAlign="center">
							<Typography variant="body1" color="secondary">
								You can get help by contacting me by email <br />
								<br />
								<a style={{ color: '#FFF', textDecoration: 'none' }} href="mailto:regisg@gmail.com">
									regisg@gmail.com
								</a>
							</Typography>
						</Box>
					)}
				</Box>
			</Grid>
		</Grid>
	);
};

export default Homepage;
