import React from 'react';
import { useParams } from 'react-router-dom';
import { useTheme, darken } from '@material-ui/core/styles';
import { Grid, Box, Typography } from '@material-ui/core';
import server from './server.jpg';

const Server = () => {
	const theme = useTheme();
	const { lang } = useParams();
	return (
		<Grid direction="row" container spacing={1} justify="center">
			<Grid item xs={12} sm={6}>
				<Box my={6} textAlign="left">
					<img src={server} alt="cardano logo" width="300px" />
				</Box>
			</Grid>
			<Grid item xs={12} sm={6}>
				<Box my={4}>
					<Typography
						variant="h1"
						style={{ textUnderlineOffset: '5px', fontSize: '3em', color: theme.palette.secondary.main }}
					>
						<span style={{ color: '#FFF', fontWeight: 'bold' }}>ADAFR</span> / ADA Staking Pool
					</Typography>
					<Box mt={4}>
						<Typography variant="body1" color="secondary">
							{lang === "fr" && `Pour assurer le bon fonctionnement du Stake Pool ADAFR, nous avons pris une marge siginificative en mobilisant un serveur haut de gamme ultra rapide : `}
							{lang === "en" && `To ensure the proper functioning of the ADAFR Stake Pool, we took a significant margin by mobilizing a high-end ultra fast server:`}
						</Typography>
						{lang === "fr" && (<ul style={{ color: theme.palette.secondary.main}}>
							<li>Serveur E3-SAT-1-16 - Xeon E3-1225v2</li>
							<li>4 coeurs | 4 threads</li>
							<li>16Gb de Ram</li>
							<li>Double alimentation</li>
							<li>Système Anti-DDOS</li>
							<li>Connexion Réseau 250 Mbps</li>
						</ul>)}
						{lang === "en" && (<ul style={{ color: theme.palette.secondary.main }}>
							<li>Server E3-SAT-1-16 - Xeon E3-1225v2</li>
							<li>4 cores | 4 threads</li>
							<li>16Gb de Ram</li>
							<li>Double alimentation</li>
							<li>Anti-DDOS system</li>
							<li>Network 250 Mbps</li>
						</ul>)}						
					</Box>
				</Box>
			</Grid>
		</Grid>
	);
};

export default Server;
