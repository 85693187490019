import React, { useState, useEffect } from "react";
import GlobalContext from "./context/GlobalContext";
import { BrowserRouter as Router } from "react-router-dom";
import BaseRouter from "./router/BaseRouter";
import * as authStorage from "./tools/authStorage";
import appFetchConfigurator from "./fetch";

export default function App() {
  const [user, setUser] = useState(null);
  const [triedUserFromStorage, setTriedUserFromStorage] = useState(false);
  useEffect(() => {
    const user = authStorage.getUser();

    // dans le router la redirection vers login intervenait
    // avant qu'on ait eu le temps d'essayer charger l'utilisateur depuis sessionStorage
    setTriedUserFromStorage(true);

    if (user) {
      setUser(user);
    }
  }, []);

  return (
    <GlobalContext.Provider
      value={{
        user,
        appFetch: appFetchConfigurator(user ? user.token : undefined)
      }}
    >
      <Router>
        {triedUserFromStorage && <BaseRouter setUser={setUser} />}
      </Router>
    </GlobalContext.Provider>
  );
}
