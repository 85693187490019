import React, { useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Paper, Grid, TextField, Button } from "@material-ui/core";
import * as authStorage from "../../../tools/authStorage";
import GlobalContext from "../../../context/GlobalContext";

const useStyles = makeStyles(theme => ({
  textField: {
    width: "100%"
  },
  label: {
    display: "block",
    marginTop: theme.spacing(1)
  },
  button: {
    marginTop: theme.spacing(1)
  },
  centered: {
    textAlign: "center"
  },
  loginPage: {
    //@ts-ignore
    background: theme.palette.background.custom1
  }
}));

const Login = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const ctx = useContext(GlobalContext);
  const [values, setValues] = useState({
    username: "",
    password: "",
    email: "tes@free.fr",
    roles: ["USER"]
  });

  const handleChange = (e) =>
    setValues({ ...values, [e.target.id]: e.target.value });

  const handleSubmit = (e) => {
    e.preventDefault();
    const { password, username } = values;
    if (ctx) {
      ctx.appFetch("/auth", {
        method: "POST",
        body: JSON.stringify({ username, password })
      }).then((user) => {
        props.setUser(user);
        authStorage.storeUser(user)
        history.push("/");
      });
    }
  };

  return (
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="center"
      className={classes.loginPage}
    >
      <Grid item xs={6} md={3}>
        {
          // obligé d'ajouter un ts-ignore car utiliser Paper comme component
          // pose pun pb de type typescript
          //@ts-ignore
          <Box component={Paper} elevation={1} p={2}>
            <form onSubmit={handleSubmit}>
              <label htmlFor="username" className={classes.label}>
                Identifiant
              </label>
              <TextField
                id="username"
                className={classes.textField}
                autoFocus
                defaultValue=""
                onChange={handleChange}
                margin="dense"
                variant="outlined"
                inputProps={{ "aria-label": "bare" }}
              />
              <label htmlFor="password" className={classes.label}>
                Mot de passe
              </label>
              <TextField
                id="password"
                type="password"
                onChange={handleChange}
                className={classes.textField}
                defaultValue=""
                margin="dense"
                variant="outlined"
                inputProps={{ "aria-label": "bare" }}
              />
              <div className={classes.centered}>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  type="submit"
                >
                  Se connecter
                </Button>
              </div>
            </form>
          </Box>
        }
      </Grid>
    </Grid>
  );
};

export default Login;
