import React, { useState, useEffect } from "react";
import { useHistory, NavLink, useParams, useLocation } from "react-router-dom";
import { fade, makeStyles, darken } from "@material-ui/core/styles";
import classNames from "classnames";
import { Grid, Button } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: {
    height: "calc(100vh - 65px)",
    width: "100%",
    fontSize: "1.3em"
  },
  content: {
    color: "#FFF",
    width: 500,
    height: 400,
    textAlign: "center"
  },
  form: {
    marginTop: theme.spacing(6)
  }
}));

const Mailing = (props) => {
  const classes = useStyles();
  const [ email, setEmail ] = useState("");
  const [ requestState, setRequestState ] = useState("");
  const { lang } = useParams();

  const handleChange = (e) => {
    setEmail(e.target.value);
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setRequestState("");
    fetch('/api/mailing', { method: "POST", body: JSON.stringify({ email } ), headers: { "Content-Type": "application/json"}})
      .then((res) => {
        if (!res.ok) {
          throw new Error();
          return;
        }
        return res.json();
      }).then(() => setRequestState('success'))
      .catch(() => setRequestState('error'))
  }

  useState(() => {
    console.log("key", requestState);
  }, [requestState])

  return (<Grid container direction="row" alignItems="center" justify="center" className={classes.root}>
        <Grid item className={classes.content}>
          <Grid container direction="column">
            <Grid item><h1>Mailing list</h1></Grid>
            <Grid item>
              {lang === "fr" && "Merci de bien vouloir vous inscrire à notre liste de diffusion : ainsi nous pourrons facilement vous tenir informé"}
              {lang === "en" && "Please subscribe to the mailing list: so that we can easily keep you informed"}
            </Grid>
            <Grid item>
              {requestState === "" && <Grid container direction="row" spacing={2} alignItems="center" className={classes.form} component="form" onSubmit={handleSubmit}>
                <Grid item>{lang === "fr" ? "Votre adresse mail" : "Your mail address"}</Grid>
                <Grid item><input name="email" type="email" onChange={handleChange} autoFocus /></Grid>
                <Grid item><Button type="submit" variant="contained" color="secondary">{lang === "fr" ? "Envoyer" : "Submit"}</Button></Grid>
              </Grid>}
              {requestState !== ""  && (
                <div className={classes.form}>
                  <h1>
                    {requestState === "error" && (lang === "fr" ? "ERREUR" : "ERROR")}
                    {requestState === "success" && (lang === "fr" ? "INSCRIPTION REÇUE !" : "INSCRIPTION REGISTERED !")}
                  </h1>
                  {requestState === "error" && <span>{lang === "fr" ? "Une erreur c'est produite, nous allons enquêter..." : "Oups ! An error occured. We will investigate"}</span>}
                  {requestState === "success" && <span>{lang === "fr" ? "Un mail avec un lien de validation va vous être envoyé prochainement ( encore un peu de dev sur l'envoi des mails )" : "A mail with a validation link will be sent soon ( still some work to do on the mailing system )"}</span>}
                </div>
              )}
            </Grid>
          </Grid>
        </Grid>
    </Grid>)
}

export default Mailing;