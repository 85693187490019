import React, { useState, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import { bech32 } from "bech32";
import maxBy from "lodash/maxBy";
import Grid from "@material-ui/core/Grid";
import { useTheme, createPalette } from "@material-ui/core/styles";
import { ResponsiveLine } from "@nivo/line";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";
import DownloadIcon from "@material-ui/icons/CloudDownload";
import EditIcon from "@material-ui/icons/Edit";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import CurrenciesDialog from "./CurrenciesDialog.js";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const theme = {
  axis: {
    textColor: "#eee",
    fontSize: "14px",
    tickColor: "#eee",
  },
  grid: {
    stroke: "#888",
    strokeWidth: 1,
  },
};

const buildChart = (datas, lang, max = 1000) => (
  <ResponsiveLine
    data={datas}
    enableArea
    lineWidth={2}
    margin={{ top: 50, right: 110, bottom: 100, left: 100 }}
    xScale={{ type: "point" }}
    yScale={{
      type: "linear",
      min: 0,
      max,
      stacked: true,
      reverse: false,
    }}
    axisTop={null}
    axisRight={null}
    axisBottom={{
      orient: "bottom",
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend: lang === "en" ? "EPOCHS" : "ÉPOQUES",
      legendOffset: 75,
      legendPosition: "middle",
    }}
    axisLeft={{
      orient: "left",
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend: lang === "en" ? "REWARDS" : "RÉCOMPENSES",
      legendOffset: -75,
      legendPosition: "middle",
    }}
    theme={{
      axis: {
        ticks: {
          text: {
            fill: "#FFF",
            fontSize: "1.1em",
          },
        },
        legend: {
          text: {
            fill: "#FFF",
            fontSize: "1.1em",
          },
        },
      },
      grid: {
        line: {
          stroke: "white",
          strokeWidth: 1,
        },
      },
    }}
    colors={{ scheme: "nivo" }}
    pointSize={10}
    pointColor={{ theme: "background" }}
    pointBorderWidth={2}
    pointBorderColor={{ from: "serieColor" }}
    pointLabel="y"
    pointLabelYOffset={-12}
    useMesh={true}
    tooltip={(props) => (
      <div
        style={{
          color: "#000",
          backgroundColor: "rgba(255,255,255,0.85)",
          padding: "10px",
        }}
      >{`${lang === "en" ? "Epoch" : "Époque"} ${props.point.data.x}, ${
        props.point.data.y
      } ADAs`}</div>
    )}
  />
);

const UserSpace = () => {
  const { lang } = useParams();
  const [datas, setDatas] = useState({});
  const [stakeId, setStakeId] = useState(null);
  const [values, setValues] = useState({});
  const [withdrawals, setWithdrawals] = useState(0);
  const [requestStateMessage, setRequestStateMessage] = useState("");
  const [loaded, setLoaded] = useState(false);
  const [value, setValue] = useState(0);
  const [open, setOpen] = useState(false);
  const [currency, setCurrency] = useState(lang === "fr" ? "EUR" : "USD");

  const handleCloseDialog = () => setOpen(false);

  const handleOpenDialog = () => setOpen(true);

  const handleValidate = (val) => {
    setOpen(false);
    setCurrency(val);
    window.localStorage.setItem("currency_v2", val);
  };

  const handleSubmit = () => {
    const output = bech32.decode(values.address, 256);
    const bytes = Buffer.from(bech32.fromWords(output.words));
    const res = bytes.toString("hex");
    const words = bech32.toWords(
      Buffer.from(`e1${res.substr(res.length - 56)}`, "hex")
    );
    const stakeAddress = bech32.encode("stake", words, 256);
    window.localStorage.setItem("cardano_stake_id_v2", stakeAddress);
    document.location.reload();
  };

  const handleChange = (e) => {
    console.log("e", e);
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleClear = () => {
    window.localStorage.removeItem("cardano_stake_id_v2");
    setStakeId(null);
    setDatas({});
  };

  useEffect(() => {
    const id = window.localStorage.getItem("cardano_stake_id_v2");
    const curr = window.localStorage.getItem("currency_v2");
    if (id) {
      setStakeId(`${id}`.length > 5 ? id : null);
      if (!curr) return;
    }
    if (curr) {
      setCurrency(curr);
      return;
    }
    setLoaded(true);
  }, []);

  useEffect(() => {
    if (stakeId) {
      fetch(`/api/${stakeId}/rewards`)
        .then((res) => {
          if (!res.ok) {
            setRequestStateMessage(
              lang === "en" ? "Id not found" : "Identifiant  non trouvée"
            );
            throw new Error();
          }
          return res.json();
        })
        .then((res) => {
          setRequestStateMessage("");
          setLoaded(true);
          const stats = {
            ...datas,
            [stakeId]: res,
          };
          console.log(stats);
          setDatas(stats);
        })
        .catch((e) =>
          console.log(
            lang === "en" ? "Id not found" : "Identifiant  non trouvée",
            e
          )
        );
    }
  }, [stakeId]);

  useEffect(() => {
    if (Object.keys(datas).length > 0) {
      fetch(`/api/${stakeId}/withdrawals`)
        .then((res) => res.json())
        .then((res) => setWithdrawals(res.amount));
    }
  }, [datas]);

  useEffect(() => {
    if (requestStateMessage !== "") {
      setTimeout(() => {
        setRequestStateMessage("");
        setValues({ ...values, rewards: "", delegation: "" });
      }, 2000);
    }
  }, [requestStateMessage]);

  const rewards = datas[Object.keys(datas)[0]];
  const onlyRewards = rewards
    ? rewards.map((d, idx, arr) => {
        return {
          y: d.amount,
          x: d.epoch,
        };
      })
    : undefined;

  const max = onlyRewards ? maxBy(onlyRewards, "y") : null;
  console.log({onlyRewards})
  return (
    <Grid direction="row" container spacing={1} justify="center">
      <CurrenciesDialog
        open={open}
        handleClose={handleCloseDialog}
        handleValidate={handleValidate}
        currency={currency}
      />
      {((loaded && datas && Object.keys(datas).length === 0) || (stakeId === null )) && (
        <Grid item xs={4} sm={4}>
          <Box
            my={6}
            style={{
              color: "#FFF",
              padding: 10,
              marginTop: 60,
              fontSize: "1.3em",
            }}
          >
            {lang === "en"
              ? `ADAFR store rewards history & epochs FIAT prices for delegators. To load rewards history please fill the form below with the address.`
              : `ADAFR sauvegarde l'historique des récompenses et les prix en FIAT. Pour récupérer vos rewards, il suffit de saisir l'adresse dans ce formulaire`}
          </Box>
          <Box
            my={6}
            style={{
              color: "#FFF",
              border: "solid 1px #FFF",
              padding: 10,
            }}
          >
            <form>
              <label>{lang === "en" ? "Address" : "Adresse"}</label>
              <TextField
                name="address"
                placeholder="addr1xxxxxx"
                variant="outlined"
                style={{
                  color: "#FFF",
                  border: "solid 1px #FFF",
                  backgroundColor: "#FFF",
                  borderRadius: 5,
                  textAlign: "right",
                }}
                onChange={handleChange}
                autoFocus
                fullWidth
              />
              <div style={{ marginTop: 20, textAlign: "right" }}>
                <Button
                  onClick={handleSubmit}
                  color="secondary"
                  variant="outlined"
                >
                  {lang === "en"
                    ? "Show my rewards"
                    : "Afficher les récompenses"}
                </Button>
              </div>
            </form>
          </Box>
        </Grid>
      )}
      {datas && Object.keys(datas).length > 0 && (
        <Grid item xs={12} sm={8} style={{ color: "#FFF" }}>
          {onlyRewards && onlyRewards.length > 0 && (
            <div style={{ height: "calc(100vh - 200px)", color: "#000" }}>
              {buildChart(
                [
                  {
                    id: "data",
                    color: "#FFF",
                    data: onlyRewards.slice(onlyRewards.length - 31, onlyRewards.length),
                  },
                ],
                lang,
                max.y * 1.25
              )}
            </div>
          )}
        </Grid>
      )}
      {datas && Object.keys(datas).length > 0 && (
        <Grid item sm={4} style={{ color: "#FFF" }}>
          <div style={{ textAlign: "left" }}>
            <h1>
              <u>
                {lang === "en"
                  ? "Rewards history"
                  : "Historique des rémunérations"}
              </u>
              <Button
                color="secondary"
                size="small"
                onClick={handleClear}
                title={lang === "en" ? "Forget me" : "Oubliez moi"}
              >
                X
              </Button>
            </h1>
          </div>
          <TableContainer>
            <Table aria-label="simple table" style={{ color: "#FFF" }}>
              <TableBody>
                <TableRow key={1}>
                  <TableCell
                    component="th"
                    scope="row"
                    style={{ color: "#FFF" }}
                  >
                    {lang === "en" ? "Total received" : "Total reçu"}
                  </TableCell>
                  <TableCell align="right" style={{ color: "#FFF" }}>
                    {rewards.reduce((m, r) => m + r.amount, 0)} ₳
                  </TableCell>
                </TableRow>
                <TableRow key={2}>
                  <TableCell
                    component="th"
                    scope="row"
                    style={{ color: "#FFF" }}
                  >
                    {lang === "en" ? "Withdrawals" : "Total des retraits"}
                  </TableCell>
                  <TableCell align="right" style={{ color: "#FFF" }}>
                    {withdrawals} ₳
                  </TableCell>
                </TableRow>
                <TableRow key={2}>
                  <TableCell
                    component="th"
                    scope="row"
                    style={{ color: "#FFF" }}
                  >
                    {lang === "en"
                      ? "Rewards remaining"
                      : "Récompenses restantes"}
                  </TableCell>
                  <TableCell align="right" style={{ color: "#FFF" }}>
                    {(
                      rewards.reduce((m, r) => m + r.amount, 0) -
                      parseFloat(withdrawals)
                    ).toFixed(6)}{" "}
                    ₳
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <div
            style={{
              marginTop: 40,
              backgroundColor: "#414c66",
              padding: 20,
              borderRadius: 5,
            }}
          >
            <h3 style={{ lineHeight: "36px" }}>
              Your currency : <strong>{currency}</strong>
              <Button onClick={handleOpenDialog} color="secondary">
                <EditIcon size="small" title="change the default currency" />
              </Button>
            </h3>
            <div style={{ textAlign: "center" }}>
              <Button
                variant="contained"
                color="default"
                style={{}}
                startIcon={<DownloadIcon />}
                href={`/api/csv?stake_address=${stakeId}&currency=${currency}&timeZone=${Intl.DateTimeFormat()
                  .resolvedOptions()
                  .timeZone.replace("/", ":")}`}
              >
                {lang === "en"
                  ? "Download as CSV"
                  : "Télécharger au format csv"}
              </Button>
              <p
                style={{ border: "solid 1px #FFF", padding: 10, marginTop: 40 }}
              >
                {lang === "en"
                  ? `We store for you the price of the ADA in your currency ( ${currency} ) for each epoch.`
                  : `Nous mémorisons pour vous le prix de l'ADA en euros pour chaque époque.`}
              </p>
            </div>
          </div>
        </Grid>
      )}
    </Grid>
  );
};

export default UserSpace;
