import React, { useState, useContext, useEffect } from 'react';

import { useParams, Link } from "react-router-dom";
import { Typography, Box, Grid } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import cardano from './cardano.png';
import NumberFormatFormat from "react-number-format";

// const ws = new WebSocket('ws://176.31.122.83:4701/');

const Homepage = (props) => {
	const theme = useTheme(); // on peut acceder au theme par un hook
	const { lang } = useParams();
	const [status, setStatus] = useState(null);

	useEffect(() => {
		if (status === null) {
			fetch('https://js.adapools.org/pools/42c3740db23c3fc36cb9f63aa8070ec01417b4d22893463c8c8a21d4/summary.json')
				.then(res => {
					if (!res.ok) {
						throw new Error();
					}
					return res.json();
				})
				.then(res => setStatus(res))
				.catch(err => setStatus(false))
		}
	}, [status])

	console.log(status)
	return (
		<Grid direction="row" container spacing={1} justify="center">
			<Grid item xs={12} sm={4}>
				<Box my={6} style={{ textAlign: "center" }}>
					<img src={cardano} alt="cardano logo" width="50%" /><br />
					{<p style={{
						color: "#000",
						border: `solid 1px ${theme.palette.secondary.main}`,
						padding: 20,
						width: "80%",
						margin: "auto",
						marginTop: "80px",
						fontSize: '1.3em',
						backgroundColor: "#FFF",
						borderRadius: 5
					}}>
						<h3>==== Important ====</h3>
						{lang === "fr"
							? <span>Note : Depuis l'époque 298, nous sommes moins chanceux, <strong>mais toujours aussi performants</strong>.<br />Patience : Pour l'époque 302, à nouveau il ne nous a été assigné que 35 slots, mais les époques chanceuses vont revenir. .<br /><br />Pour en savoir plus, voir la page "PERFORMANCE et RECOMPENSES"</span>
							: <span>Note : Since epoch 298, we are less lucky, <strong>but still very performant</strong>.<br />Be patient : for the epoch 302 we were assigned only 35 slots, but luckier epochs will be back.<br /><br />For more infos, see the page "PERFORMANCE et REWARDS"</span>}
						<span>
						</span>
					</p>}
					{status && <>
						<h2 style={{ color: "#FFF", fontWeight: "normal" }}><strong>{status.data.delegators}</strong> {lang === "en" ? "wallets delegated" : "portefeuilles délégués"}<br />
							Total : <strong><NumberFormatFormat
								value={parseInt(status.data.total_stake, 10) / 1000000}
								displayType="text"
								prefix={lang === "fr" ? null : "₳ "}
								suffix={lang === "en" ? null : " ₳ "}
								thousandSeparator={lang === "fr" ? " " : ","}
							/></strong>
							{lang === "fr" ? " délégués" : " staked"}
						</h2>
					</>}
				</Box>
			</Grid>
			<Grid item xs={12} sm={8}>
				<Grid container direction="column">
					<Grid item xs={12}>
						<Box my={4}>
							<Typography
								variant="h1"
								style={{ textUnderlineOffset: '5px', fontSize: '3em', color: theme.palette.secondary.main, marginTop: 15 }}
							>
								<span style={{ color: '#FFF', fontWeight: 'bold' }}>ADAFR</span> / ADA Staking Pool
							</Typography>
							<Typography
								variant="h5"
								style={{ textUnderlineOffset: '5px', fontSize: '1.95em', color: theme.palette.secondary.main }}
							>
								{lang === "fr" ? "Un stake pool sécurisé par un " : "A stake pool backed by a solid "}<span style={{ color: '#FFF', fontWeight: 'bold' }}>{
									lang === "en" ? "3M PLEDGE" : " PLEDGE DE 3M"
								}</span>
							</Typography>
							<Typography variant="body1" color="secondary">PoolId: 42c3740db23c3fc36cb9f63aa8070ec01417b4d22893463c8c8a21d4 </Typography>
							{lang === "en" && <Typography
								variant="h5"
								style={{ textUnderlineOffset: '5px', fontSize: '1.3em', color: theme.palette.secondary.main, marginTop: 10, textAlign: "right" }}
							>
								...Powered with low CO2 footprint electricity from France.
							</Typography>}
							<hr style={{ textUnderlineOffset: '5px', fontSize: '1.3em', color: "#FFF", marginTop: 20 }} />
							<Box mt={4}>
								{lang === "fr" && <Typography variant="body1" color="secondary" style={{ marginBottom: "1em" }}>Le Stake Pool Cardano <span style={{ color: "#FFF" }}>ADAFR</span> est bâtit sur de solides fondations matérielles et logicielles afin d'assurer au mieux sa fonction : <em>en votre nom</em> sécuriser le réseau cardano.</Typography>}
								{lang === "fr" && <Typography variant="body1" color="secondary">Nul besoin de laisser tourner 24h/24h un ordinateur pour participer au fonctionnement du réseau : en délégant votre participation à <span style={{ color: "#FFF" }}>ADAFR</span>, vous recevrez <span style={{ color: "#FFF" }}> plus de <strong>98% </strong></span>de votre rémunération, les <span style={{ color: "#FFF" }}><strong>1.50%</strong></span> restant servant à payer le serveur et le travail fournit pour assurer le bon fonctionnement du Stake Pool <span style={{ color: "#FFF" }}>ADAFR</span>.</Typography>}
								{lang === "en" && <Typography variant="body1" color="secondary" style={{ marginBottom: "1em" }}>The Stake Pool Cardano <span style={{ color: "#FFF" }}> ADAFR </span> is built on solid hardware and software foundations in order to best ensure its function: <em> on your behalf </ em> secure the cardano network.</Typography>}
								{lang === "en" && <Typography variant="body1" color="secondary">No need to let a computer run 24 hours a day to participate in the operation of the network: by delegating your participation to <span style={{ color: "#FFF" }}> ADAFR </span>, you will receive <span style={{ color: "#FFF" }}>  more than <strong> 98% </strong> </span> of your remuneration, the <span style={{ color: "#FFF" }}> <strong> 1.50% </ strong > </span> remaining are used to pay the server and the work provided to ensure the proper functioning of the Stake Pool <span style={{ color: "#FFF" }}> ADAFR </span>.</Typography>}
							</Box>
							<Box mt={4} textAlign="center">
								{lang === "fr" && <Typography variant="h5" color="secondary" style={{ marginBottom: "1em" }}>Frais: <span style={{ color: "#FFF" }}>1.50 %</span> / <small>Frais fixes : <span style={{ color: "#FFF" }}>170 ₳ / époque ( minimum imposé par les paramètres de Shelley )</span></small></Typography>}
								{lang === "en" && <Typography variant="h5" color="secondary" style={{ marginBottom: "1em" }}>Fees: <span style={{ color: "#FFF" }}>1.50 %</span> / <small>Fixed fees : <span style={{ color: "#FFF" }}>170 ₳ / Epoch ( minimum set by Shelley parameters )</span></small></Typography>}
							</Box>
						</Box>
					</Grid>
					<Grid item xs={12}>
						<Grid container direction="row" spacing={1}>
							<Grid item xs={8}>
								<Box my={2} padding={2}>
									<Typography
										variant="h1"
										style={{ textUnderlineOffset: '5px', fontSize: '3em', color: "#FFF", fontWeight: "bold", marginBottom: "0.5em" }}
									>{lang === "fr" ? "Historique de vos récompenses" : "Rewards history"}</Typography>
									<Typography
										variant="p"
										style={{ textUnderlineOffset: '5px', fontSize: '1.5em', color: theme.palette.secondary.main }}>
										{lang === "fr" && <span>À ma connaissance, <strong>ADAFR est le seul stake pool</strong> à vous offrir des fonctionnalités bien utiles :</span>}
										{lang === "fr" && (<ul>
											<li>Vous pouvez à tout moment consulter l'historique de vos réspompenses sur le site.<br /> ( seulement sur votre navigateur wen -> sécurisé )</li>
											<li>Vous pouvez télécharger l'historique sous forme de csv ou fichier excel.</li>
											<li>ADAFR mémorise le prix de l'ADA en euros pour chaque époque. Dans certain cas, c'est utile pour les déclarations fiscales</li>
										</ul>)}
										{lang === "en" && <span>AFAIK, <strong>ADAFR is the only stake pool</strong> to include some useful functionalities :</span>}
										{lang === "en" && (<ul>
											<li>You can easily see your rewards history on the website. ( only on your web browser -> secured )</li>
											<li>You can download datas as csv or  excel file.</li>
											<li>ADAFR store the price of ADA in many currencies for each epoch .It can be useful in fiscal matters</li>
										</ul>)}
									</Typography>
									<div style={{ textAlign: "center", fontSize: '1.5em', color: theme.palette.secondary.main }}>
										<Link to={`/${lang}/user`} style={{ textAlign: "center", fontSize: '1.5em', color: theme.palette.secondary.main }}>{lang === "fr" ? "Utiliser l'outil" : "Use the tool"}</Link>
									</div>
								</Box>
							</Grid>
							<Grid item xs={4}>
								<Box my={2} padding={2} style={{ backgroundColor: "rgba(255, 255, 255, 0.15)", height: "100%", color: theme.palette.secondary.main }}>
									<Typography
										variant="h1"
										style={{ textUnderlineOffset: '5px', fontSize: '3em', color: "#FFF", fontWeight: "bold", marginBottom: "0.5em" }}
									>{lang === "fr" ? "Dernières nouvelles" : "Latest news"}</Typography>
									<ul>
										{props.news.filter(n => n.lang === lang).map((n) => <li key={n.id}><Link to={`/${lang}/news/${n.id}`} style={{ textDecoration: "none", fontSize: '1.2em', color: theme.palette.secondary.main }}>{n.title}</Link></li>)}
									</ul>
								</Box>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default Homepage;
