import * as React from "react";
import { Route, Switch } from "react-router";
import Layout from "../ui/pages/layout";
import Login from "../ui/pages/login";

const BaseRouter = props => {
  return (
    <Switch>
      <Route
        path="/:lang?"
        render={
          () => <Layout setUser={props.setUser} />
        }
      />
    </Switch>
  );
};

export default BaseRouter;
