
const appFetchConfigurator = (token) => async (url, options) => {
  const auth = token ? { Authorization: "Bearer " + token } : {};
  let headers = {
    "Content-Type": "application/json",
    ...auth
  };

  const fetchOptions = {
    method: "GET",
    headers,
    ...options
  };

  const res = await fetch(url, fetchOptions);

  if (!res.ok) {
    throw new Error(res.statusText);
  }

  const contentType = res.headers.get("Content-Type") || "";

  if (res.status !== 204 && contentType === "application/json") {
    return res.json();
  }

  return res.text();
};

export default appFetchConfigurator;
