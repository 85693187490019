import { red } from '@material-ui/core/colors';
import { createMuiTheme, lighten, darken } from '@material-ui/core/styles';

// A custom theme for this app
const theme = createMuiTheme({
  overrides: {
    MuiButton: {
      root: {
        textTransform: "none",
        borderRadius: "3px",
      },
      contained: {
        boxShadow: "none",
      },
    },
    MuiAppBar: {
      colorPrimary: {
        color: "#000",
        backgroundColor: "transparent"
      },        
    },
    MuiToolbar: {
      regular: {
        // '@media (min-width:600px) and (orientation: landscape)': {
        //   minHeight: 48
        // }
      }
    },
    MuiPaper: {
      elevation1: {
        boxShadow: "none",
        borderRadius: 3
      }
    }
  },  
  palette: {
    primary: {
      main: "rgba(0,0,0,0)",
    },
    secondary: {
      main: `${darken("#FFF", 0.2)}`,
    },
    error: {
      main: red.A400,
    },
    background: {
      default: '#fff',
      custom1: `linear-gradient(45deg, #556cd6 10%, ${lighten("#556cd6", 0.2)})`,
    },
  },
});

export default theme;
