export const storeUser = (user) => {
  window.sessionStorage.setItem("user", JSON.stringify(user));
};

export const clearUser = () => {
  window.sessionStorage.removeItem("user");
};

export const getUser = () => {
  const user = window.sessionStorage.getItem("user");
  return user ? JSON.parse(user) : null;
};
