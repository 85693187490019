import React, { useState, useEffect } from "react";
import { useHistory, NavLink, useParams, useLocation } from "react-router-dom";
import { fade, makeStyles, darken } from "@material-ui/core/styles";
import classNames from "classnames";
import { Grid, Button } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: {
    height: "calc(100vh - 65px)",
    width: "100%",
    fontSize: "1.3em"
  },
  content: {
    color: "#FFF",
    width: 500,
    height: 400,
    textAlign: "center"
  },
  form: {
    marginTop: theme.spacing(6)
  }
}));

const MailingCheck = (props) => {
  const classes = useStyles();
  const [ requestState, setRequestState ] = useState("");
  const { key, lang } = useParams();

  useEffect(() => {
    if (key) {
      fetch(`/api/check?key=${key}&lang=${lang}`,{headers: { "Content-Type": "application/json"}})
      .then((res) => {
        if (!res.ok) {
          console.log('ici');
          throw new Error();
          return;
        }
        return res.json();
      }).then(() => setRequestState('success'))
      .catch(() => {
        console.log('catch');
        setRequestState('error');
      })      
    }
  }, []);

  useState(() => {
    console.log("key", key, requestState);
  }, [requestState])

  return (<Grid container direction="row" alignItems="center" justify="center" className={classes.root}>
        <Grid item className={classes.content}>
          <Grid container direction="column">
            <Grid item><h1>Mailing list</h1></Grid>

            <Grid item>
                <div className={classes.form}>
                <h1>
                    {requestState === "" && (lang === "fr" ? "VALIDATION EN COURS... " : "VALIDATION...")}
                    {requestState === "error" && (lang === "fr" ? "ERREUR" : "ERROR")}
                    {requestState === "success" && (lang === "fr" ? "INSCRIPTION VALIDEE !" : "INSCRIPTION VALIDATED !")}
                  </h1>
                  {requestState === "error" && <span>{lang === "fr" ? "Ce lien n'est plus valide" : "This link is not valid anymore"}</span>}
                  {requestState === "succes" && <span>{lang === "fr" ? "Votre inscription est valicée. Merci !" : "Your registration is validated. Thanks !"}</span>}
                </div>
            </Grid>
          </Grid>
        </Grid>
    </Grid>)
}

export default MailingCheck;