import React from "react";
import { Typography, Box, Grid } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import cardano from "./cardano.png";
import chart from "./Chart.png";
import adapool from "./adapool.png";

const PerformancesFr = () => {
  const theme = useTheme(); // on peut acceder au theme par un hook

  return (
    <Grid direction="row" container spacing={1} justify="center">
      <Grid item xs={12} sm={3}>
        <Box my={6} style={{ textAlign: "left"}}>
          <img src={cardano} alt="cardano logo" width="400px" />
          <p style={{ color: "#FFF", fontSize: '1.5em', border: "solid 1px #FFF", padding: 20, margin: 20 }}>Rappel : ce stake pool possède un pledge élevé ( 3M ) parce que le possesseur de 
            ce pledge fait confiance à ADAFR pour lui donner d'excelentes récompenses.
          </p>
        </Box>
      </Grid>
      <Grid item xs={12} sm={9}>
        <Box my={4}>
          <Typography
            variant="h1"
            style={{
              textUnderlineOffset: "5px",
              fontSize: "3em",
              marginBottom: "1em",
              color: theme.palette.secondary.main
            }}
          >
            <span style={{ color: "#FFF", fontWeight: "bold" }}>ADAFR</span> /
            Performances & récompenses
          </Typography>
          <Grid container direction="row" spacing={1}>
            <Grid item xs={8} style={{ color: "#FFF", fontSize: '1.5em' }}>
              <p>Chaque époque, pour un même <em>"Live Stake"</em>, une sorte de loterie donne à ADAFR le nombre de blocs à forger.
              En moyenne, le nombre de blocs pour un pledge donnée est constant. Mais il y a des variations selon les époques.
              Il y a des époques chanceuses et d'autres qui le sont moins.</p>
              <p>
                <strong>ADAFR est très performant</strong> : nous avons manqué un seul bloc sur les 61 blocs que nous avons eu à forger. Mais
                préparez vous à obtenir des récompenses au dessous de la moyenne lors des époques chanceuses qui seront compensées par des récompenses
                au dessus de la moyenne lors des époques chanceuses. 
              </p>
              <p>Par exemple, Dans le tableau ci-contre vous pouvez constater que les époques 211 et 212 étaient chanceuses, les 213 et 214 le seront moins...
                L'objectif d'ADAFR c'est de forger presque tous les blocs qui nous sont alloués. ( le protocole de Cardano ne permet pas d'obbtenir 100%), et ainsi
                de vous donner sur la durée un excellent rendement.</p>
            </Grid>
            <Grid item xs={4} style={{textAlign: "right"}}>
              <img src={adapool} alt="Nombres de blocs sur pooltool.io"/>
            </Grid>
            <Grid item xs={12} >
              <div style={{ backgroundColor: "#FFF", textAlign: "center"}}>
                <img src={chart} alt="Nombres de blocs sur pooltool.io"/>
              </div>
              <p style={{ color: "#FFF", fontSize: '1.5em' }}>
                  Vous pouvez voir ci-dessus pour l'époque 214 la probabilité d'avoir X blocs à la "loterie".</p>
              <p style={{ color: "#FFF", fontSize: '1.2em' }}>Vous trouverez ces données <a target="__blank" style={{ color: "#FFF", fontWeight: "bold" }} href="https://adapools.org/pool/42c3740db23c3fc36cb9f63aa8070ec01417b4d22893463c8c8a21d4">sur cette page</a>, dans l'onglet rewards</p>
            </Grid>
          </Grid>
            
        </Box>
      </Grid>
    </Grid>
  );
};

export default PerformancesFr;
