import * as React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import Homepage from "../ui/pages/homepage";
import About from "../ui/pages/about";
import Contact from "../ui/pages/contact";
import User from "../ui/pages/user";
import Performances from "../ui/pages/performances";
import NotFound from "../ui/pages/defaults/NotFound";
import Server from "../ui/pages/server";
import Mailing from "../ui/pages/mailing";
import News from "../ui/pages/news";
import MailingCheck from "../ui/pages/mailingCheck";

// const PagePrivee = () => <h1>Page visible par ADMIN et USER</h1>;

// const ATest = withAuthorization(PagePrivee, ["SUPERADMIN"]);

const MainRouter = props => {
  return (
    <Switch>
      <Route path="/" exact>
        <Redirect to="/en" />
      </Route>
      <Route path="/:lang" exact render={() => <Homepage news={props.news} />} />
      <Route path="/:lang/about" exact component={About} />
      <Route path="/:lang/contact" exact component={Contact} />
      <Route path="/:lang/server" exact component={Server} />
      <Route path="/:lang/user" exact component={User} />
      <Route path="/:lang/performances" exact component={Performances} />
      <Route path="/:lang/mailing/:key" exact component={MailingCheck} />
      <Route path="/:lang/news/:index" exact render={() => <News news={props.news} />} />
      <Route path="/:lang/mailing" exact component={Mailing} />
      <Route component={NotFound} />
    </Switch>
  );
};

export default MainRouter;
