import React, { useContext, useState, useEffect } from "react";
import { useHistory, NavLink, useParams, useLocation } from "react-router-dom";
import classNames from "classnames";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import { fade, makeStyles, darken } from "@material-ui/core/styles";
import MenuIcon from "@material-ui/icons/Menu";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import MainRouter from "../../../router/MainRouter";
// import StatusBar from "../../common/StatusBar";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    position: "relative"
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  title: {
    flexGrow: 1,
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block"
    }
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25)
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto"
    }
  },
  searchIcon: {
    width: theme.spacing(7),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  inputRoot: {
    color: "inherit"
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: 120,
      "&:focus": {
        width: 200
      }
    }
  },
  button: {
    color: theme.palette.getContrastText(theme.palette.primary.main),
    marginLeft: theme.spacing(1)
  },
  containedPrimary: {
    border: `solid 1px transparent`,
    "&:hover": {
      backgroundColor: "transparent",
      boxShadow: "none",
      border: `solid 1px ${darken(
        theme.palette.getContrastText(theme.palette.primary.main),
        0.2
      )}`
    }
  },
  titleBtn: {
    fontSize: "2.2em",
    fontWeight: "normal"
  },
  selected: {
    borderBottom: "solid 3px #FFF",
    "&:hover": {
      borderBottom: "solid 3px #FFF"
    }
  },
  saturation: {
    border: "solid 2px #FFF",
    padding: theme.spacing(2),
    color: "#FFF",
    textAlign: "center",
    fontSize: "16px"
  }
}));

export default function Layout(props) {
  const classes = useStyles();
  const [news, setNews] = useState([]);
  const history = useHistory();
  const { lang, index } = useParams();
  const { pathname } = useLocation();

  const pathSplt = pathname.split("/");
  const page = pathSplt.length > 2 ? `/${pathSplt[2]}` : "";

  useEffect(() => {
    if (news.length === 0) {
      fetch("/api/news")
        .then((res) => res.json())
        .then((datas) => setNews(datas));
    }
  }, [news])

  return (
    <div className={classes.root}>
      <AppBar elevation={0} position="static">
        <Toolbar>
          {false && (
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="open drawer"
            >
              <MenuIcon />
            </IconButton>
          )}
          <div className={classes.title}>
            <Button
              to={`/${lang}`}
              component={NavLink}
              className={classNames(classes.button, classes.titleBtn)}
              color="primary"
              variant="text"
            >
              ADAFR STAKING POOL
            </Button>
          </div>
          <Button
            to={`/${lang}/about`}
            component={NavLink}
            activeClassName={classes.selected}
            className={classNames(classes.button, classes.containedPrimary)}
            color="primary"
            variant="contained"
          >
            {lang === "fr" ? "À PROPOS" : "ABOUT"}
          </Button>
          <Button
            to={`/${lang}/server`}
            component={NavLink}
            activeClassName={classes.selected}
            className={classNames(classes.button, classes.containedPrimary)}
            color="primary"
            variant="contained"
          >
            {lang === "fr" ? "LE SERVEUR" : "HARDWARE"}
          </Button>
          <Button
            to={`/${lang}/performances`}
            component={NavLink}
            activeClassName={classes.selected}
            className={classNames(classes.button, classes.containedPrimary)}
            color="primary"
            variant="contained"
          >
            {lang === "fr" ? "PERFORMANCES et RÉCOMPENSES" : "PERFOMANCES & REWARDS"}
          </Button>          
          {false && <Button
            to={`/${lang}/mailing`}
            component={NavLink}
            activeClassName={classes.selected}
            className={classNames(classes.button, classes.containedPrimary)}
            color="primary"
            variant="contained"
          >
            {lang === "fr" ? "LISTE DE DIFFUSION" : "MAILING"}
          </Button>}
          <Button
            to={`/${lang}/user`}
            component={NavLink}
            activeClassName={classes.selected}
            className={classNames(classes.button, classes.containedPrimary)}
            color="primary"
            variant="contained"
          >
            {lang === "fr" ? "VOTRE COMPTE" : "USER PAGE"}
          </Button>        
          <Button
            to={`/${lang}/contact`}
            component={NavLink}
            activeClassName={classes.selected}
            className={classNames(classes.button, classes.containedPrimary)}
            color="primary"
            variant="contained"
          >
            {lang === "fr" ? "NOUS CONTACTER" : "CONTACT"}
          </Button>
          <Button
            to={`/${lang === "fr" ? "en" : "fr"}${page}${page === "/news" ? `/${pathSplt[3]}` : ""}`}
            component={NavLink}
            activeClassName={classes.selected}
            className={classNames(classes.button, classes.containedPrimary)}
            color="primary"
            variant="contained"
          >
            {lang === "fr" ? "EN" : "FR"}
          </Button>
        </Toolbar>
      </AppBar>
      <Container maxWidth="xl">
        {false && <div className={classes.saturation}>
          {lang === "fr" &&
            <span>Note aux "stakers" : Bien qu'ADAFR soit très performant (> 92% des blocs validés), le nombre de blocs alloués au pool pour une ou deux époques peut parfois être faible (ex: <strong>époque 83 seulement 5 blocs</strong>). <strong>Cela n'a aucun impact sur vos récompenses à moyen terme</strong>: le nombre moyen de blocs sera nickel. Par souci de transparence, nous publions le nombre de blocs assignés pour l'époque actuelle sur la page "Performances". Restez avec nous :-)</span>}
          {lang === "en" &&
            <span>Note to "stakers" : Even if the ADAFR behaves extremely well (> 92% of blocks minted), the number of blocks allocated to the pool for one or two epochs can sometimes be low (ex: <strong>epoch 83 only 5 blocks</strong>). <strong>This has no impact on your rewards in the medium term</strong> : the average number of blocks will be fine. Commited with transparency, we publish the number of the blocks of the current epoch on the "Performances" page. Stay with us :-)</span>}
        </div>}
        <MainRouter lang={lang} news={news} />
      </Container>
      {/* <StatusBar /> */}
    </div>
  );
}
