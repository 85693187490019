import React, { useState, useContext, useEffect } from 'react';

import { useParams, Link } from "react-router-dom";
import { Typography, Box, Grid } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import cardano from './cardano.png';
import NumberFormatFormat from "react-number-format";

// const ws = new WebSocket('ws://176.31.122.83:4701/');

const News = (props) => {
	const theme = useTheme(); // on peut acceder au theme par un hook
	const { lang, index } = useParams();
	const [status, setStatus] = useState(null);

	useEffect(() => {
		if (status === null) {
			fetch('/api/status')
				.then(res => {
					if (!res.ok) {
						throw new Error();
					}
					return res.json();
				})
				.then(res => setStatus(res))
				.catch(err => setStatus(false))
		}
	}, [status])

	const newsItem = props.news.find(n => `${n.id}` === index);

	if (!newsItem) return null;

	return (
		<Grid direction="row" container spacing={1} justify="center">
			<Grid item xs={12} sm={4}>
				<Box my={6} style={{textAlign: "center"}}>
					<img src={cardano} alt="cardano logo" width="50%" /><br />
					<p style={{
						color: "#000",
						border: `solid 1px ${theme.palette.secondary.main}`,
						padding: 20,		
						width: "80%",
						margin: "auto",
						marginTop: "80px",
						fontSize: '1.3em',	
						backgroundColor: 	"#FFF",
						borderRadius: 5
					}}>
						{lang === "fr" ? <span>Pour être tenu informé des nouveautés<br />et suivre les annonces, vous pouvez</span> : "To be kept informed of news and announcements, please, "}<br />{" "}
					<Link  style={{ textAlign: "center", fontSize: '1.3em', color: "#000" }} to={`/${lang}/mailing`}>{lang === "fr" ? "vous inscrire" : "register"}</Link> <span>{lang === "fr" ? "à notre mailing list" : " to the mailing list"}
					</span>
					</p>
					{status && <>
						<h2 style={{color: "#FFF", fontWeight: "normal"}}><strong>{status.length}</strong> {lang === "en" ? "wallets delegated" : "portefeuilles délégués"}<br />
							Total : <strong><NumberFormatFormat
								value={status.reduce((memo, deleg) => memo + parseInt(deleg.stake.replace(/\s|,/gi, ""), 10), 0) / 1000000}
								displayType="text"
								prefix={lang === "fr" ? null : "₳ "}
								suffix={lang === "en" ? null : " ₳ "}
								thousandSeparator={lang === "fr" ? " " : ","}
							/></strong>
							{lang === "fr" ? " délégués" : " staked"}
						</h2>
					</>}
				</Box>
			</Grid>
			<Grid item xs={12} sm={8} style={{ color: "#FFF"}}>
				<h1>{newsItem.title}</h1>
				<div dangerouslySetInnerHTML={{ __html: newsItem.text }} style={{ fontSize: "1.2em"}}/>
			</Grid>
		</Grid>
	);
};

export default News;
