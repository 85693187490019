import React, { useState, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Typography, Box, Grid } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import cardano from "./cardano.png";
import chart from "./Chart.png";
import adapool from "./adapool.png";
import PerformancesFr from "./fr";
import PerformancesEn from "./en";

const Performances = () => {
  const theme = useTheme(); // on peut acceder au theme par un hook
  const { lang } = useParams();
  const [datas, setDatas] = useState(null);

  return lang === "en" ? <PerformancesEn /> : <PerformancesFr />
};

export default Performances;
