import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import currencies from "./currencies.json";

const CurrenciesDialog = ({ open, handleClose, handleValidate, currency }) => {
  const descriptionElementRef = React.useRef(null);
  const [value, setValue] = React.useState(currency);

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">Select your currency</DialogTitle>
        <DialogContent dividers>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            <FormControl component="fieldset">
              <FormLabel component="legend">Available currencies</FormLabel>
              <RadioGroup
                aria-label="currencies"
                name="currencies"
                value={value}
                onChange={handleChange}
              >
                {Object.keys(currencies).map((c) => <FormControlLabel
                  value={c}
                  control={<Radio />}
                  label={currencies[c]}
                />)}
              </RadioGroup>
            </FormControl>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary" variant="outlined">
            Cancel
          </Button>
          <Button onClick={(e) => handleValidate(value, e)} color="secondary" variant="contained">
            Validate
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CurrenciesDialog;
