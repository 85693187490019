import React from "react";
import { Typography, Box, Grid } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import cardano from "./cardano.png";
import chart from "./Chart.png";
import adapool from "./adapool.png";

const PerformancesEn = () => {
  const theme = useTheme(); // on peut acceder au theme par un hook

  return (
    <Grid direction="row" container spacing={1} justify="center">
      <Grid item xs={12} sm={3}>
        <Box my={6} style={{ textAlign: "left"}}>
          <img src={cardano} alt="cardano logo" width="400px" />
          <p style={{ color: "#FFF", fontSize: '1.5em', border: "solid 1px #FFF", padding: 20, margin: 20 }}>Remember : this pool has a high pledge ( 3M ) because the owner of this pledge
            trust ADAFR to give him very good rewards.
          </p>
        </Box>
      </Grid>
      <Grid item xs={12} sm={9}>
        <Box my={4}>
          <Typography
            variant="h1"
            style={{
              textUnderlineOffset: "5px",
              fontSize: "3em",
              marginBottom: "1em",
              color: theme.palette.secondary.main
            }}
          >
            <span style={{ color: "#FFF", fontWeight: "bold" }}>ADAFR</span> /
            Performances & rewards
          </Typography>
          <Grid container direction="row" spacing={1}>
            <Grid item xs={8} style={{ color: "#FFF", fontSize: '1.5em' }}>
              <p>Each epoch, for a same <em>Live Stake</em>, a kind of "lotery" gives us the number of blocks we have to forge.
              On the average, the number of blocks for a given stake is constant. But there are variations by epochs.
              There are lucky ones and less lucky ones.</p>
              <p>
                <strong>ADAFR is very performant</strong> : we have missed only 1 block / 61 blocks to forge. But be prepared
                to earn  below the average rewards on less lucky epochs that will be compensated by above the average rewards
                in much lucky epochs that, by protocol design, will follow less lucky ones. 
              </p>
              <p>For example, on this data table you can see that epochs 211 & 212 were lucky, 213 & 214 will be less luckier...
                The ADAFR goal is to forge almost every block he has to mint, ( 100% is by protocol design not achievable ), so that
                our delegators on the average will have best rewards.</p>
            </Grid>
            <Grid item xs={4} style={{textAlign: "right"}}>
              <img src={adapool} alt="Nombres de blocs sur pooltool.io"/>
            </Grid>
            <Grid item xs={12} >
              <div style={{ backgroundColor: "#FFF", textAlign: "center"}}>
                <img src={chart} alt="Nombres de blocs sur pooltool.io"/>
              </div>
              <p style={{ color: "#FFF", fontSize: '1.5em' }}>Here you can see on epoch 214 the probability to have X blocks on "loterie".</p>
              <p style={{ color: "#FFF", fontSize: '1.2em' }}>You can find these datas on <a target="__blank" style={{ color: "#FFF", fontWeight: "bold" }}href="https://adapools.org/pool/42c3740db23c3fc36cb9f63aa8070ec01417b4d22893463c8c8a21d4">this page</a>, on the rewards tab</p>
            </Grid>
          </Grid>
            
        </Box>
      </Grid>
    </Grid>
  );
};

export default PerformancesEn;
