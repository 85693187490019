import React, { useState, useContext, useEffect } from 'react';
import { useParams, Link } from "react-router-dom";
import { Typography, Box, Grid } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import cardano from './cardano.png';

const Homepage = () => {
	const theme = useTheme(); // on peut acceder au theme par un hook
    const { lang } = useParams();
	return (
		<Grid direction="row" container spacing={1} justify="center">
			<Grid item xs={12} sm={6}>
				<Box my={6}>
					<img src={cardano} alt="cardano logo" width="400px" />
				</Box>
			</Grid>
			<Grid item xs={12} sm={6}>
				<Box my={4}>
					<Typography
						variant="h1"
						style={{ textUnderlineOffset: '5px', fontSize: '3em', color: theme.palette.secondary.main }}
					>
						<span style={{ color: '#FFF', fontWeight: 'bold' }}>ADAFR</span> / ADA Staking Pool
					</Typography>
					<Box mt={4}>
						{lang === "fr" && (<Typography variant="body1" color="secondary">
						<span style={{ color: "#FFF"}}>ADAFR</span> est un Stake Pool <Link to={`/${lang}/server`} style={{color: theme.palette.secondary.main }}>performant et sécurisé</Link> vous permettant de participer facilement au fonctionnement de la blockchain Cardano.
						</Typography>)}
					</Box>
					<Box mt={1}>
						{lang === "fr" && (<Typography variant="body1" color="secondary">
							Cardano étant une blockchain Proof Of Stake, tout personne détenant des ADAs peut participer à la sécurisation de la blockchain. En récompense, elle recevra une rémunération en ADAs.
						</Typography>)}						
					</Box>
					<Box mt={1}>
						{lang === "fr" && (<Typography variant="body1" color="secondary">
							Seulement pour participer, il faut laisser allumé un ordinateur 24h/24 et ce n'est ni pratique ni économique...
						</Typography>)}						
					</Box>		
					<Box mt={1}>
						{lang === "fr" && (<Typography variant="body1" color="secondary">
							C'est là qu'interviennent les Stake Pools : vous déléguez en toute sécurité votre droit à participer au fonctionnement de la blockchain. ( Vous ne transmettez pas 
							vos ADAs mais un certificat ). En échange de ce service le Stake Pool retient une partie de vos gains.							
						</Typography>)}						
					</Box>
					<Box mt={1}>
						{lang === "fr" && (<Typography variant="body1" color="secondary">
							Avec <span style={{ color: "#FFF"}}>ADAFR</span>, vous conservez plus de <span style={{ color: "#FFF"}}>98% de vos gains</span>. ADAFR applique <span style={{ color: "#FFF"}}>1.50%</span> de frais
						</Typography>)}						
					</Box>		
					<Box mt={4}>
						{lang === "en" && (<Typography variant="body1" color="secondary">
						<span style={{ color: "#FFF"}}>ADAFR</span> is an efficient and secure Stake Pool allowing you to easily participate in the functioning of the Cardano blockchain.
						</Typography>)}
					</Box>
					<Box mt={1}>
						{lang === "en" && (<Typography variant="body1" color="secondary">
						Since Cardano is a Proof Of Stake blockchain, anyone who owns ADAs can participate in securing the blockchain. As a reward, he will receive remuneration in ADAs.
						</Typography>)}						
					</Box>
					<Box mt={1}>
						{lang === "en" && (<Typography variant="body1" color="secondary">
						But to participate, you must leave a computer on 24/24 and it is neither practical nor cheap ...
						</Typography>)}						
					</Box>		
					<Box mt={1}>
						{lang === "en" && (<Typography variant="body1" color="secondary">
						This is where the Stake Pools come in: you safely delegate your right to participate in the functionning of the blockchain. (You do not send your ADAs but a certificate). In exchange for this service, the Stake Pool withholds part of your winnings.
						</Typography>)}						
					</Box>
					<Box mt={1}>
						{lang === "en" && (<Typography variant="body1" color="secondary">
						With ADAFR, you keep more than <span style={{ color: "#FFF"}}>98%</span> of your earnings. ADAFR applies <span style={{ color: "#FFF"}}>1.50%</span> fees</Typography>)}						
					</Box>																					
				</Box>
			</Grid>
		</Grid>
	);
};

export default Homepage;
